import React, { useContext, useEffect, useState } from 'react'
import { IAceEditorProps } from 'react-ace'
import dynamic from 'next/dynamic'
import { IoSaveOutline } from 'react-icons/io5'
import { HiOutlineClipboardCopy } from 'react-icons/hi'
import SaveAsModal from './saveAsModal' // Import the SaveAsModal component
import { FilePersistServiceContext } from 'app/api/filePersistService'

const AceEditor = dynamic(
  async () => {
    const ace = await import('react-ace')
    require('ace-builds/webpack-resolver')
    require('ace-builds/src-noconflict/ext-language_tools')
    require('ace-builds/src-noconflict/theme-nord_dark') // Change theme to Nord Dark
    require('ace-builds/src-noconflict/mode-markdown')
    require('ace-builds/src-noconflict/mode-yaml')
    require('ace-builds/src-noconflict/mode-json')
    require('ace-builds/src-noconflict/mode-javascript')
    require('ace-builds/src-noconflict/mode-typescript')
    require('ace-builds/src-noconflict/mode-java')
    require('ace-builds/src-noconflict/mode-sh')
    require('ace-builds/src-noconflict/mode-python')
    require('ace-builds/src-noconflict/mode-text')
    require('ace-builds/src-noconflict/keybinding-vim')
    return ace
  },
  { ssr: false },
)

const getModeFromFilename = (filename?: string) => {
  if (!filename) return 'text'
  const extension = filename.split('.').pop()
  switch (extension) {
    case 'js':
      return 'javascript'
    case 'py':
      return 'python'
    case 'java':
      return 'java'
    case 'ts':
      return 'typescript'
    case 'sh':
      return 'sh'
    case 'md':
      return 'markdown'
    case 'java':
      return 'java'
    case 'yaml':
      return 'yaml'
    case 'json':
      return 'json'
    default:
      return 'text'
  }
}
export default function Ace(
  props: IAceEditorProps & {
    fileName?: string
    outerStyle?: React.CSSProperties
    outerClassName?: string
  },
) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const filePersistService = useContext(FilePersistServiceContext)
  const [value, setValue] = useState(props.value ?? '')
  const [fileName, setFileName] = useState(props.fileName)

  const handleSaveClick = () => {
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleSave = async (filename: string) => {
    await filePersistService.saveFile(filename, value)
    setIsModalOpen(false)
  }

  useEffect(() => {
    setValue(props.value ?? '')
  }, [props.value])

  useEffect(() => {
    setFileName(props.fileName)
  }, [props.fileName])

  return (
    <div
      className={`bg-[#262626] relative flex flex-col rounded-s ${props.outerClassName}`}
      style={props.outerStyle}
    >
      {props.fileName && (
        <div className='flex'>
          <div className='text-sm text-gray-400 text-center m-1'>
            {props.fileName}
          </div>
          <div className='flex items-center justify-center'>
            <button
              className='hover:bg-neutral-700 p-1 rounded'
              onClick={handleSaveClick}
            >
              <IoSaveOutline size={20} />
            </button>
          </div>
        </div>
      )}
      {/* @ts-ignore */}
      <AceEditor
        {...props}
        value={value}
        onChange={v => {
          props.onChange && props.onChange(v)
          setValue(v)
        }}
        mode={props.mode ?? getModeFromFilename(fileName)}
        editorProps={{ $blockScrolling: true, ...props.editorProps }}
      />
      <button
        className='absolute top-2 right-2 hover:bg-neutral-700 p-1 rounded'
        style={{ zIndex: 10 }}
      >
        <HiOutlineClipboardCopy size={20} />
      </button>
      <SaveAsModal
        isOpen={isModalOpen}
        currentPath={props.fileName ?? ''}
        onClose={handleModalClose}
        onSave={handleSave}
      />
    </div>
  )
}
