import { execScript } from 'app/api/exec'
import { AxiosError } from 'axios'
import { atom } from 'jotai'
import { splitAtom } from 'jotai/utils'
import { StakzExecution, StakzParseError } from 'service/scriptEval'

// Define a type for the command history entries
export type CommandHistoryEntry = {
  command: string
  output: string
  status: 'success' | 'error' | 'loading' | 'not-started'
  scriptId?: string
}

type ScriptResults = {
  result: StakzExecution | StakzParseError | undefined
  scriptId: string
}

const _scriptResults = atom<ScriptResults>({
  result: { text: '', type: 'success' },
  scriptId: '',
})

// Create an atom to store the command history
const _commandHistoryAtom = atom<CommandHistoryEntry[]>([
  { command: '', output: '', status: 'not-started', scriptId: undefined },
])

export const commandHistoryAtom = splitAtom(_commandHistoryAtom)

export const scriptResults = atom<StakzExecution | StakzParseError | undefined>(
  get => get(_scriptResults),
  // @ts-ignore
  (get, set, newResult: ScriptResults) => {
    if (newResult == undefined) {
      set(_scriptResults, {
        result: { text: '', type: 'success' },
        scriptId: '',
      })
      return
    }
    if (newResult.result?.type == 'error') {
      set(_scriptResults, newResult)
    } else if (newResult.result?.type == 'success') {
      const command = get(commandHistoryAtom).find(
        f => get(f).scriptId == newResult.scriptId,
      )
      if (newResult.result.type == 'success' && command) {
        // @ts-ignore
        set(command, prev => ({ ...prev, command: newResult.result.text }))
      } else if (newResult?.result.type == 'success') {
        const unusedCommand = get(commandHistoryAtom).find(
          c => get(c).status == 'not-started' && get(c).command == '',
        )
        if (unusedCommand) {
          set(unusedCommand, prev => ({
            ...prev,
            // @ts-ignore
            command: newResult.result.text,
            scriptId: newResult.scriptId,
          }))
          set(_commandHistoryAtom, prev => [
            ...prev,
            { command: '', output: '', status: 'not-started' },
          ])
        } else {
          set(_commandHistoryAtom, prev => [
            ...prev,
            {
              // @ts-ignore
              command: newResult.result.text,
              output: '',
              status: 'not-started',
              scriptId: newResult.scriptId,
            },
          ])
        }
      }
    }
  },
)

export function hash(s: string) {
  var hash = 0,
    i,
    chr
  if (s.length === 0) return hash
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export const executeScript = atom(null, async (get, set, script: string) => {
  let commandHistory = get(commandHistoryAtom)
  // Hash the script to identify it
  const scriptId = hash(script).toString()
  let commandAtom = commandHistory.find(c => get(c).command == script)
  if (!commandAtom) {
    set(_commandHistoryAtom, prev => [
      ...prev.filter(c => c.status != 'not-started' || c.command != ''),
      { command: script, output: '', status: 'loading', scriptId: scriptId },
    ])
    // Grab the newly created command atom
    commandHistory = get(commandHistoryAtom)
    commandAtom = commandHistory[commandHistory.length - 1]
  }

  let command: CommandHistoryEntry = get(commandAtom)
  let lastCommand = get(commandHistory[commandHistory.length - 1])
  if (command == lastCommand || lastCommand.status != 'not-started') {
    set(_commandHistoryAtom, prev => [
      ...prev,
      { command: '', status: 'not-started', output: '' },
    ])
  }
  if (!command) {
    set(_commandHistoryAtom, prev => [
      ...prev,
      { command: script, output: '', status: 'loading' },
    ])
    command = get(commandHistory[commandHistory.length - 1])
  } else {
    set(commandAtom, prev => ({ ...prev, status: 'loading' }))
  }
  try {
    const resp = await execScript(script)
    set(commandAtom, prev => ({
      ...prev,
      output: resp.data,
      status: 'success',
    }))
  } catch (e: any) {
    let message =
      e instanceof AxiosError && e.response?.data
        ? e.response.data
        : (e.message ?? 'Unknown error')
    set(commandAtom, prev => ({ ...prev, output: message, status: 'error' }))
  }
})
