import React, { useEffect, useState } from 'react'

import AceEditor from './ace-editor'

interface CodeWidgetProps {
  content: string
}

const fileNameRegex = /```([\w|\.]+)\n/

const CodeWidget: React.FC<CodeWidgetProps> = ({ content }) => {
  const [renderContent, setRenderContent] = useState('')
  const [mode, setMode] = useState('javascript')
  const [fileName, setFileName] = useState<string | undefined>()

  const getModeFromFilename = (filename?: string) => {
    if (!filename) return 'text'
    const extension = filename.split('.').pop()
    switch (extension) {
      case 'js':
        return 'javascript'
      case 'py':
        return 'python'
      case 'java':
        return 'java'
      case 'ts':
        return 'typescript'
      case 'sh':
        return 'sh'
      case 'md':
        return 'markdown'
      case 'java':
        return 'java'
      case 'yaml':
        return 'yaml'
      case 'json':
        return 'json'
      default:
        return 'text'
    }
  }

  useEffect(() => {
    setRenderContent(
      content.replace(fileNameRegex, '').replace(/```/g, '').trim(),
    )
    const filenameMatch = content.match(fileNameRegex)
    const fileName = filenameMatch ? filenameMatch[1] : undefined
    setFileName(fileName)
    setMode(getModeFromFilename(fileName))
  }, [content])

  return (
    <AceEditor
      mode={mode}
      theme='nord_dark'
      name='code-editor'
      value={renderContent}
      minLines={2}
      fileName={fileName}
      maxLines={60}
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        useWorker: false,
      }}
      onChange={setRenderContent}
      highlightActiveLine={false}
      className={'bg-neutral-800 border-none'}
      outerClassName={'bg-neutral-800 border-none'}
      keyboardHandler='vim'
      height='100%'
      width='100%'
      wrapEnabled={true}
      enableLiveAutocompletion={true}
    />
  )
}

export default CodeWidget
