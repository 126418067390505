'use client'

import React from 'react'
import { useAtom, useAtomValue } from 'jotai'
import yaml from 'js-yaml'
import { dataValuesObj } from '../../store/stakzStore'
import AceEditor from 'components/ace-editor'

export default function DataPreview() {
  const [data, setData] = useAtom(dataValuesObj)
  const yamlData = yaml.dump(data)

  return (
    <AceEditor
      mode='yaml'
      theme='nord_dark'
      name='dataPreviewEditor'
      value={yamlData}
      // readOnly={true}
      highlightActiveLine={false}
      className={'bg-neutral-800 border-none'}
      fontSize={14}
      width='100%'
      height='100%'
      onChange={newValue => {
        try {
          // @ts-ignore
          setData(yaml.load(newValue))
        } catch (e) {
          console.error(e)
        }
      }}
      setOptions={{
        useWorker: false,
        showLineNumbers: false,
        tabSize: 2,
      }}
    />
  )
}
