// Import DOMParser
//

import { FragmentMetadata, FragmentType } from 'store/stakzStore'

export type NodeType = Node & {
  text: string
  getAttribute: (name: string) => string | null
}

export function parseHtml(html: string): NodeType[] {
  const parser = new DOMParser()
  const nodes = parser.parseFromString(html, 'text/html')
  const hw = nodes.createTreeWalker(nodes.head, NodeFilter.SHOW_ALL)
  const tw = nodes.createTreeWalker(nodes.body, NodeFilter.SHOW_ALL)
  const retNodes: NodeType[] = []
  for (var node = hw.nextNode(); node != null; node = tw.nextNode()) {
    // @ts-ignore
    retNodes.push(node)
  }
  for (var node = tw.nextNode(); node != null; node = tw.nextNode()) {
    // @ts-ignore
    retNodes.push(node)
  }
  return retNodes
}

function checkForContextSwitch(
  line: string,
  currentContext: FragmentType,
): FragmentType | false {
  if (currentContext == 'md') {
    const trimmedLine = line.trim()
    if (trimmedLine.startsWith('<') && !line.trim().startsWith('</')) {
      return 'widget'
    } else if (trimmedLine.startsWith('```')) {
      return 'code'
    } else {
      return false
    }
  } else if (currentContext == 'stakz') {
    if (line.search('```') >= 0 && line.search('```Stakz') < 0) {
      return 'md'
    }
  } else if (currentContext == 'code' && line.trimEnd().endsWith('```')) {
    return 'md'
  }
  return false
}

function findMarkupClose(
  line: string,
  startPosition: number = 0,
): number | undefined {
  for (var i = startPosition; i < line.length; i++) {
    if (line[i] == "'" || line[i] == '"') {
      const quote = line[i]
      do {
        i++
      } while (line[i] != quote && i < line.length)
    }
    if (line[i] == '>') {
      return i
    }
  }
  return undefined
}

type NodeParseMetadata = {
  line: string
  searchNodeName?: string
  type: 'start' | 'end' | 'self-closing' | 'start-open'
  currentNodeNameCount: number
}

const DefaultNodeParseMetadata: Omit<NodeParseMetadata, 'line'> = {
  type: 'start',
  currentNodeNameCount: 0,
}

function getMarkupNodeName(
  nodeParseMetadata: NodeParseMetadata,
): NodeParseMetadata {
  let { line, searchNodeName, currentNodeNameCount, type } = nodeParseMetadata

  if (!line) {
    return { ...nodeParseMetadata, currentNodeNameCount: 0 }
  }
  if (line.trim().startsWith('<!--')) {
    return { ...nodeParseMetadata, currentNodeNameCount: 0 }
  }

  function iterateWhitespace(i: number, direction: 1 | -1 = 1): number {
    while (line[i] == ' ' || line[i] == '\t') {
      i += direction
    }
    return i
  }

  if (nodeParseMetadata.type == 'start-open') {
    for (var i = 0; i < line.length; i++) {
      if (line[i] == '>') {
        i = iterateWhitespace(--i, -1)
        if (line[i] == '/') {
          return {
            line: line.slice(i + 1),
            type: 'self-closing',
            searchNodeName: nodeParseMetadata.searchNodeName,
            currentNodeNameCount: 0,
          }
        }
        return {
          line: line.slice(i + 1),
          type: 'start',
          searchNodeName: nodeParseMetadata.searchNodeName,
          currentNodeNameCount: 1,
        }
      }
    }
    return { ...nodeParseMetadata, line: '', currentNodeNameCount: 0 }
  }

  var nodeName: string
  var closeIndex

  for (var i = 0; i < line.length; i++) {
    if (line[i] == '<') {
      iterateWhitespace(++i)
      // This is ending
      if (line[i] == '/') {
        i = iterateWhitespace(++i)
        type = 'end'
      }
      let nodeEndIndex = line.length
      for (var j = i; j < line.length; j++) {
        if (line[j] == ' ' || line[j] == '>') {
          nodeEndIndex = j
          break
        }
      }
      nodeName = line.slice(i, nodeEndIndex)
      i = nodeEndIndex
      closeIndex = findMarkupClose(line, i)
      if (closeIndex == undefined) {
        return {
          line: line.slice(i),
          type: 'start-open',
          searchNodeName: nodeName,
          currentNodeNameCount: 0,
        }
      } else {
        const j = iterateWhitespace(closeIndex - 1, -1)
        if (line[j] == '/') {
          return {
            line: line.slice(i),
            type: 'self-closing',
            searchNodeName: nodeName,
            currentNodeNameCount: 0,
          }
        }
      }
      if (!searchNodeName) {
        searchNodeName = nodeName
      }
      const count = type == 'start' ? 1 : -1
      if (nodeName == searchNodeName) {
        currentNodeNameCount =
          count +
          (getMarkupNodeName({
            line: line.slice(closeIndex + 1),
            searchNodeName,
            type,
            currentNodeNameCount,
          })?.currentNodeNameCount ?? 0)
        return {
          line: line.slice(closeIndex + 1),
          type: 'start',
          searchNodeName,
          currentNodeNameCount,
        }
      }
    }
  }
  return {
    ...nodeParseMetadata,
    line: '',
    currentNodeNameCount: 0,
  }
}

export function parseStakz(content: string): FragmentMetadata[] {
  var currentContent = ''
  var currentContext: FragmentType = 'md'
  const newFragmentMetadata: FragmentMetadata[] = []
  const lines = content.split('\n')
  const pushFragmentMetadata = (widget: FragmentMetadata) => {
    newFragmentMetadata.push(widget)
    currentContext = 'md'
    currentContent = ''
  }
  for (var lineNum = 0; lineNum < lines.length; lineNum++) {
    const line = lines[lineNum] + '\n'
    const contextSwitch = checkForContextSwitch(line, currentContext)
    if (contextSwitch) {
      // Add widget for nonempty content
      if (currentContent.trim() != '') {
        pushFragmentMetadata({
          content:
            contextSwitch == 'md' ? currentContent + line : currentContent,
          fragmentType: currentContext,
          startPos:
            newFragmentMetadata.length == 0
              ? 0
              : newFragmentMetadata[newFragmentMetadata.length - 1].endPos,
          endPos: lineNum,
        })
      }
      currentContent = line
      if (contextSwitch == 'widget') {
        const startingLineNum = lineNum
        var nodeParseMetadata = getMarkupNodeName({
          line,
          ...DefaultNodeParseMetadata,
        })

        while (
          nodeParseMetadata?.type == 'start-open' ||
          nodeParseMetadata.currentNodeNameCount > 0
        ) {
          if (++lineNum >= lines.length) {
            newFragmentMetadata.push({
              fragmentType: 'md',
              content: 'Error, missing end tag\n' + currentContent,
              startPos: startingLineNum,
              endPos: lineNum,
            })
            return newFragmentMetadata
          }
          const line = lines[lineNum]
          currentContent += line + '\n'
          nodeParseMetadata.line = line
          let newNodeParseMetadata: NodeParseMetadata =
            getMarkupNodeName(nodeParseMetadata)
          do {
            nodeParseMetadata = {
              ...newNodeParseMetadata,
              currentNodeNameCount:
                nodeParseMetadata.currentNodeNameCount +
                newNodeParseMetadata.currentNodeNameCount,
            }
            newNodeParseMetadata = getMarkupNodeName(nodeParseMetadata)
          } while (newNodeParseMetadata.line.trim() != '')
          nodeParseMetadata = {
            ...newNodeParseMetadata,
            currentNodeNameCount:
              nodeParseMetadata.currentNodeNameCount +
              newNodeParseMetadata.currentNodeNameCount,
          }
        }
        pushFragmentMetadata({
          fragmentType: 'widget',
          content: currentContent,
          startPos: startingLineNum,
          endPos: lineNum,
        })
        continue
      }

      currentContext = contextSwitch
      // Don't add end tag to content
      if (currentContext == 'md') {
        currentContent = ''
      } else {
        currentContent = line
      }
    } else {
      currentContent += line
    }
  }
  if (currentContent.trim() != '') {
    pushFragmentMetadata({
      content: currentContent,
      fragmentType: currentContext,
      startPos:
        newFragmentMetadata.length == 0
          ? 0
          : newFragmentMetadata[newFragmentMetadata.length - 1].endPos,
      endPos: lines.length,
    })
  }
  return newFragmentMetadata
}
