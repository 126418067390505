import { FilePersistServiceContext } from 'app/api/filePersistService'
import { isFileAnonymous, isFilePathAnonymous } from 'app/api/fileService'
import { placeholder } from 'drizzle-orm'
import { useState, useEffect, useRef, useContext } from 'react'

export interface SaveAsModalProps {
  isOpen: boolean
  currentPath: string
  onClose: () => void
  onSave: (newPath: string) => void
  saveAsText?: string
  children?: React.ReactNode
  placeholderText?: string
}

export default function SaveAsModal({
  isOpen,
  onClose,
  onSave,
  currentPath,
  saveAsText,
  children,
  placeholderText,
}: SaveAsModalProps) {
  const [newPath, setNewPath] = useState(currentPath)
  const inputRef = useRef<HTMLInputElement>(null)
  saveAsText = saveAsText || 'Save As'

  useEffect(() => {
    if (isFilePathAnonymous(currentPath)) {
      const currentPathParts = currentPath.split('/')
      currentPathParts.pop()
      setNewPath(currentPathParts.join('/') + '.md')
    } else {
      setNewPath(currentPath)
    }
  }, [currentPath])

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isOpen])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newPath.trim() !== '') {
      onSave(newPath)
    }
  }

  if (!isOpen) return null

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-neutral-800/50  z-50'>
      <div className='bg-neutral-800 p-6 rounded-lg shadow-lg w-1/3 h-3/6 flex flex-col'>
        <h3 className='text-lg font-semibold mb-4'>Save As</h3>
        <input
          type='text'
          value={newPath}
          onKeyDown={handleKeyDown}
          onChange={e => setNewPath(e.target.value)}
          placeholder={placeholderText ?? 'Enter new file path'}
          className='w-full p-2 border border-gray-300 rounded mb-4'
          ref={inputRef}
        />
        <div className='flex justify-end'>
          <button
            onClick={() => onSave(newPath)}
            className='bg-blue-500 text-white px-4 py-2 rounded mr-2'
          >
            Save
          </button>
          <button
            onClick={onClose}
            className='bg-gray-300 text-black px-4 py-2 rounded'
          >
            Cancel
          </button>
        </div>
        {children}
      </div>
    </div>
  )
}
