import { atom } from 'jotai'
import { dataValuesObj } from './stakzStore'
import yaml from 'js-yaml'

const _configStore = atom<{ [key: string]: any }>({})

import {
  githubFileService,
  LocalFile,
  localFileService,
} from 'app/api/fileService'
import { currentFile, currentFiles } from './fileStore'

export const _activeConfigs = atom<LocalFile[]>([])
export const CONFIG_PATH = '.stakz/configs/'

export const currentConfigPathPrefix = atom(get => {
  const activeFile = get(currentFile)
  return `${CONFIG_PATH}${activeFile.path.replace('.md', '')}`
})

export const inScopeConfigPaths = atom(get => {
  const configPath = get(currentConfigPathPrefix)
  const allFiles = get(currentFiles)
  return allFiles
    .filter(c => c.path.startsWith(configPath))
    .map(f => f.path.replace(configPath + '/', ''))
})

export const activeConfigs = atom(
  get => get(_activeConfigs),
  (get, set, newConfigs: LocalFile[]) => {
    set(_activeConfigs, newConfigs)
    const cf = get(currentFile)
    function setConfigAndDataValues() {
      set(_activeConfigs, newConfigs)
      const newDataObj = newConfigs.reduce((acc, c) => {
        try {
          const obj: any = yaml.load(c.content ?? '')
          return { ...acc, ...obj }
        } catch (e) {
          return acc
        }
      }, {})
      set(dataValuesObj, {
        ...get(dataValuesObj),
        ...newDataObj,
      })
    }
    for (const config of newConfigs) {
      if (!config.path.endsWith('.yaml')) {
        config.path += '.yaml'
      }
      if (!config.content) {
        const getFileContent =
          cf.type === 'local'
            ? localFileService.getFileContent(config)
            : githubFileService.getFileContent({
                ...cf,
                path: config.path,
              })
        getFileContent.then(content => {
          config.content = content
          setConfigAndDataValues()
        })
      }
    }
    setConfigAndDataValues()
  },
)

export const addConfig = atom(null, (get, set, config: LocalFile) => {
  set(activeConfigs, [...get(activeConfigs), config])
})

export const activeConfigPaths = atom(
  get => {
    const currentFilePath = get(currentConfigPathPrefix)
    return get(activeConfigs).map(c =>
      c.path.replace(currentFilePath + '/', '').replace('.yaml', ''),
    )
  },
  (get, set, newPaths: string[]) => {
    const currentFilePath = get(currentConfigPathPrefix)
    const ac = get(activeConfigs)
    const newFiles = newPaths
      .map(path => (path.endsWith('.yaml') ? path : path + '.yaml'))
      .map(path =>
        path.startsWith(currentFilePath) ? path : `${currentFilePath}/${path}`,
      )
      .map(
        path =>
          ac.find(f => f.path === path) ??
          ({
            path,
            type: 'local',
            saved: true,
          } as LocalFile),
      )
    set(activeConfigs, newFiles)
  },
)

export const addConfigPath = atom(null, (get, set, path: string) => {
  set(activeConfigPaths, [...get(activeConfigPaths), path])
})

export const removeConfig = atom(null, (get, set, config: LocalFile) => {
  set(
    activeConfigs,
    get(activeConfigs).filter(c => c.path !== config.path),
  )
})

export const configStore = atom(
  get => get(_configStore),
  (get, set, newConfig: { [key: string]: any }) => {
    set(_configStore, newConfig)
    set(dataValuesObj, { ...dataValuesObj, newConfig })
  },
)
