import { Button } from '@mantine/core'
import { useAtom } from 'jotai'
import React from 'react'
import { IoCodeSharp, IoDocumentOutline } from 'react-icons/io5'
import { MdVerticalSplit } from 'react-icons/md'
import { viewType } from '../store/stakzStore'

export default function SplitViewSwitch() {
  const [_viewType, setViewType] = useAtom(viewType)

  const backgroundEditor =
    _viewType == 'editor' ? ' bg-gray-600' : ' bg-netural-600'
  const backgroundSplit =
    _viewType == 'split' ? ' bg-gray-600' : ' bg-netural-600'
  const backgroundPreview =
    _viewType == 'preview' ? ' bg-gray-600' : ' bg-netural-600'

  const baseClasses =
    ' hover:bg-gray-600 rounded-md shadow-md p-2 cursor-pointer '

  return (
    <div className='m-2 items-center space-between flex space-x-1'>
      <div
        className={baseClasses + backgroundPreview}
        onClick={() => setViewType('preview')}
      >
        <IoDocumentOutline size={20} />
      </div>
      <div
        className={baseClasses + backgroundSplit}
        onClick={() => setViewType('split')}
      >
        <MdVerticalSplit size={20} />
      </div>
      <div
        className={baseClasses + backgroundEditor}
        onClick={() => setViewType('editor')}
      >
        <IoCodeSharp size={20} />
      </div>
    </div>
  )
}
