import React, { useState, useEffect, useContext, useReducer } from 'react'

import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
  currentFile as currentFileAtom,
  currentFiles as currentFilesAtom,
} from 'store/fileStore'
import SaveAsModal from './saveAsModal'
import { LocalFile, localFileService } from 'app/api/fileService'
import { dataValuesObj as dataValuesObjAtom } from 'store/stakzStore'
import yaml from 'js-yaml'
import AceEditor from './ace-editor'
import { FilePersistServiceContext } from 'app/api/filePersistService'
import { MultiSelect } from '@mantine/core'
import { IoSaveOutline } from 'react-icons/io5'
import {
  activeConfigPaths,
  CONFIG_PATH,
  inScopeConfigPaths as inScopeConfigPathsAtom,
  currentConfigPathPrefix as currentConfigPathPrefixAtom,
  addConfig as addConfigAtom,
} from 'store/configStore'

const ConfigSelector = () => {
  const currentFile = useAtomValue(currentFileAtom)
  const [selectedConfigs, setSelectedConfigs] = useAtom(activeConfigPaths)
  const addConfig = useSetAtom(addConfigAtom)
  const [isSaveAsModalOpen, setSaveAsModalOpen] = useState(false)
  const configPrefix = CONFIG_PATH + currentFile.path.replace('.md', '') + '/'
  const inScopeConfigPaths = useAtomValue(inScopeConfigPathsAtom).map(f =>
    f.replace('.yaml', ''),
  )

  const filePersistService = useContext(FilePersistServiceContext)
  const dataValues = useAtomValue(dataValuesObjAtom)
  const setCurrentFiles = useSetAtom(currentFilesAtom)
  const currentConfigPathPrefix = useAtomValue(currentConfigPathPrefixAtom)

  const [currentDataValues, setCurrentDataValues] = useState(
    yaml.dump(dataValues),
  )

  useEffect(() => {
    setCurrentDataValues(yaml.dump(dataValues))
  }, [dataValues])

  const onSave = async (configName: string) => {
    const configFileName = configName + '.yaml'
    const newConfig: LocalFile = {
      type: 'local',
      path: `${currentConfigPathPrefix}/${configFileName}`,
      saved: true,
      content: currentDataValues,
    }
    setSaveAsModalOpen(false)
    await filePersistService.saveFile(newConfig.path, currentDataValues)
    setCurrentFiles(files => [...files, newConfig])
    addConfig(newConfig)
  }

  return (
    <>
      <div className='flex m-2'>
        <MultiSelect
          placeholder='Select Config'
          id='config-selector'
          value={selectedConfigs}
          onChange={setSelectedConfigs}
          searchable
          data={inScopeConfigPaths}
          hidePickedOptions
        />

        <button
          className='ml-2 mr-2 hover:bg-neutral-700 p-1 rounded'
          onClick={() => setSaveAsModalOpen(true)}
        >
          <IoSaveOutline size={20} />
        </button>
      </div>
      <SaveAsModal
        isOpen={isSaveAsModalOpen}
        currentPath={''}
        onSave={onSave}
        onClose={() => setSaveAsModalOpen(false)}
        placeholderText='Enter Config Name'
      >
        <AceEditor
          keyboardHandler='vim'
          className={'bg-neutral-800 border-none size-full'}
          style={{
            width: '100%',
            flex: 1,
            padding: '4em',
            minWidth: '0',
            top: 0,
            left: 0,
            right: 0,
          }}
          wrapEnabled={true}
          mode='yaml'
          theme='nord_dark'
          setOptions={{
            enableBasicAutocompletion: false,
          }}
          enableLiveAutocompletion={true}
          onChange={setCurrentDataValues}
          value={currentDataValues}
          name='UNIQUE_ID_OF_DIV'
          editorProps={{
            $blockScrolling: true,
          }}
        />
      </SaveAsModal>
    </>
  )
}

export default ConfigSelector
