// @ts-nocheck

import { atom } from 'jotai'
import { parseHtml } from 'service/stakzParser'
import yaml from 'js-yaml'

import { atomFamily, atomWithStorage } from 'jotai/utils'
import { parseStakz } from 'service/stakzParser'
import deepEqual from 'fast-deep-equal'

export type FragmentType = 'md' | 'stakz' | 'widget' | 'code'

export type FragmentMetadata = {
  content: string
  fragmentType: FragmentType
  startPos: number
  endPos: number
}

export class MapWithDefault<K, T> extends Map<K, T> {
  default: (_: K) => T

  get(key: K): T {
    if (!this.has(key)) {
      this.set(key, this.default(key))
    }
    return super.get(key) as T
  }

  constructor(defaultFunction: (_: K) => T, entries = []) {
    super(entries)
    this.default = defaultFunction
  }
}

const _stakzFileContext = atom('')
export const stakzFileContext = atom(
  get => get(_stakzFileContext),
  (get, set, content: string) => {
    set(_stakzFileContext, content)

    const newFragmentMetadata = parseStakz(content)

    const allVars: { [key: string]: any } = newFragmentMetadata
      .filter(m => m.fragmentType == 'widget')
      .flatMap(m => parseHtml(m.content))
      .filter(m => m && m.getAttribute)
      .reduce((partial: { [key: string]: any }, next) => {
        if (next.textContent) {
          // @ts-ignore
          const key = next.getAttribute('var') || next.getAttribute('label')
          if (!key) {
            return partial
          }
          if (next.nodeName == 'JSON' || next.nodeName == 'YAML') {
            const parseFunc = next.nodeName == 'JSON' ? JSON.parse : yaml.load
            try {
              const parsed = parseFunc(next.textContent)
              if (!deepEqual(parsed, dataValues(key))) {
                partial[key] = parsed
              }
            } catch (e) {}
          } else if (
            (next.nodeName == 'FIELD' || next.nodeName == 'SELECT') &&
            dataValues(key) != next.textContent
          ) {
            partial[key] = next.textContent
          }
        }
        return partial
      }, {})

    const dataCopy = { ...get(dataValuesObj) }
    Object.keys(dataCopy).forEach(key => {
      if (allVars[key] == undefined) {
        delete dataCopy[key]
      }
    })

    set(fragmentMetdata, newFragmentMetadata)
    Object.entries(allVars).forEach(([key, value]) => {
      if (value != dataCopy[key] || dataValues(key) == '') {
        set(dataValues(key), value)
      }
    })
    dataValues.getParams().forEach(key => {
      if (allVars[key] == undefined) {
        dataValues.remove(key)
      }
    })
  },
)

export const fragmentMetdata = atom<FragmentMetadata[]>([])
export const expandedSideNav = atom(true)
export var stakzServerBaseUrl = 'http://localhost:3001'

export const dataValues = atomFamily(
  (key: string) =>
    atom<string | object | number>(
      get => get(dataValuesObj)[key],
      (get, set, newValue) => {
        if (!deepEqual(get(dataValuesObj)[key], newValue)) {
          set(dataValuesObj, { ...get(dataValuesObj), [key]: newValue })
        }
      },
    ),
  deepEqual,
)

const _dataValuesObj = atom<{ [key: string]: any }>({})

export const dataValuesObj = atom<{ [key: string]: any }>(
  get => get(_dataValuesObj),
  (_, set, newValues: { [key: string]: any }) => {
    set(_dataValuesObj, newValues)
    Object.entries(newValues).forEach(([key, value]) => {
      set(dataValues(key), value)
    })
  },
)

export type ViewType = 'editor' | 'preview' | 'split'
export const viewType = atom<ViewType>('preview')
export const executionOutput = atom('')
export const executionStatus = atom<'success' | 'failure'>('success')

const _newScriptHash = atom('')
export const newScriptHash = atom(get => get(_newScriptHash))
export const scriptResultsInitiated = atom(null, (_, set) => {
  // random string
  set(_newScriptHash, Math.random().toString(36).substring(7))
})

export const sideNavExpanded = atom(false)
export const stakzServerHealthy = atom(false)

export const token = atomWithStorage('token', '')
export const activeBottomView: Atom<'prompt-engineering' | 'terminal'> =
  atom('prompt-engineering')
