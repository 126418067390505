'use client'

import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import '../styles/globals.css'
import '@mantine/core/styles.css'
import { ColorSchemeScript, MantineProvider } from '@mantine/core'
import DoubleNavbar from './navbar/layout'
import HeaderSearch from './header/layout'
import axios from 'axios'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
  stakzFileContext,
  stakzServerHealthy as stakzServerHealthyAtom,
  token as tokenAtom,
} from 'store/stakzStore'
import { usePathname } from 'next/navigation'
import { currentFile as currentFileAtom } from 'store/fileStore'
import MainWrapper from './mainWrapper/layout'
import { activeViewType as activeViewTypeAtom } from 'store/fileStore'
import { isFileAnonymous, LocalFile, localFileService } from './api/fileService'
import SaveAsModal from 'components/saveAsModal'

export default function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {
  const stakzFileContent = useAtomValue(stakzFileContext)
  const activeViewType = useAtomValue(activeViewTypeAtom)
  const token = useAtomValue(tokenAtom)
  const [currentFile, setCurrentFile] = useAtom(currentFileAtom)
  const [isSaveAsModalOpen, setSaveAsModalOpen] = useState(false)
  const path = usePathname()
  const segments = path.split('/')
  const [stakzServerHealthy, setStakzServerHealthy] = useAtom(
    stakzServerHealthyAtom,
  )

  function checkStakzServerHealth() {
    axios
      .get('http://localhost:3001/health', {
        headers: { Authorization: `${token}` },
      })
      .then(() => {
        setStakzServerHealthy(true)
      })
      .catch(() => {
        setStakzServerHealthy(false)
      })
  }

  useEffect(() => {
    const interval = setInterval(checkStakzServerHealth, 5000)
    return () => clearInterval(interval)
  }, [token])

  useEffect(() => {
    if (segments[1] == 'browse') {
      if ((segments?.length ?? 0) >= 4) {
        setCurrentFile({
          org: segments[2],
          repo: segments[3],
          path: segments.slice(4).join('/'),
          type: 'github',
        })
      }
    } else if (segments[1] == 'editor') {
      const path = segments.slice(2).join('/')
      if (path == '') {
        return
      }
      setCurrentFile({
        path: segments.slice(2).join('/'),
        saved: true,
        type: 'local',
      })
    }
  }, [path])
  checkStakzServerHealth()

  const saveAsFile = (newPath: string) => {
    const newFile: LocalFile = {
      ...currentFile,
      path: newPath,
      type: 'local',
      saved: true,
    }
    if (stakzFileContent) {
      localFileService.saveFile(newFile, stakzFileContent)
    }
    setCurrentFile(newFile)
    setSaveAsModalOpen(false)
  }

  const saveWithBrowser = async () => {
    if ('showSaveFilePicker' in window) {
      try {
        const options = {
          suggestedName: currentFile.path,
          types: [
            {
              description: 'Text Files',
              accept: { 'text/plain': ['.txt'], 'text/markdown': ['.md'] },
            },
          ],
        }
        const fileHandle = await (window as any).showSaveFilePicker(options)
        const writable = await fileHandle.createWritable()
        await writable.write(currentFile.content)
        await writable.close()
      } catch (error) {
        console.error('Error saving file:', error)
      }
    } else {
      console.error('File System Access API is not supported in this browser.')
    }
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        e.preventDefault()
        if (isFileAnonymous(currentFile) || e.shiftKey) {
          if (stakzServerHealthy) {
            setSaveAsModalOpen(true)
          } else {
            saveWithBrowser()
          }
          return
        }
        saveFile()
      }
    }

    const saveFile = () => {
      if (isFileAnonymous(currentFile)) {
        return
      }
      // Implement your save logic here
      console.log('File saved:', currentFile.path)
      // Example: Save the file content to the server or local storage
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [currentFile, stakzServerHealthy])

  return (
    <html lang='en'>
      <head>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width'
        />
        <ColorSchemeScript />
      </head>
      <body className='h-screen w-screen'>
        <MantineProvider defaultColorScheme='dark'>
          <div className='size-full flex flex-col justify-center'>
            <HeaderSearch />
            <div className='flex w-full flex-grow-1 flex-shrink-1 min-h-0'>
              <div className='border-r-1 border-neutral-700 bg-neutral-900/50'>
                <DoubleNavbar />
              </div>
              <div className='size-full flex-grow-1 flex-shrink-1 overflow-hidden'>
                {activeViewType == 'editor' || segments.length > 2 ? (
                  <MainWrapper />
                ) : (
                  children
                )}
              </div>
            </div>
          </div>
          <SaveAsModal
            currentPath={currentFile.path}
            isOpen={isSaveAsModalOpen}
            onClose={() => setSaveAsModalOpen(false)}
            onSave={saveAsFile}
          />
        </MantineProvider>
      </body>
    </html>
  )
}
