'use client'

import { useCallback, useContext } from 'react'
import React from 'react'
import { FilePersistServiceContext } from 'app/api/filePersistService'
import { debounce } from 'lodash'
import { stakzFileContext, viewType } from '../../store/stakzStore'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import StakzView from '../stakzView/layout'
import AceEditor from 'components/ace-editor'
import { currentFile as currentFileAtom } from 'store/fileStore'

type RowColumn = { row: number; column: number }
type AceEditEvent = {
  action: 'remove' | 'insert'
  start: RowColumn
  end: RowColumn
  lines: string[]
}

export default function MainContent(props: { filePath: string }) {
  const [content, setContent] = useAtom(stakzFileContext)
  const filePersistService = useContext(FilePersistServiceContext)
  const _viewType = useAtomValue(viewType)
  const [currentFile, setCurrentFile] = useAtom(currentFileAtom)

  const debouncedSave = useCallback(
    debounce(async (newVal: string) => {
      await filePersistService.saveFile(props.filePath, newVal)
      if (currentFile.type === 'local') {
        setCurrentFile({ ...currentFile, content: newVal, saved: true })
      }
    }, 2500),
    [props.filePath],
  )

  const onUpdate = useCallback(
    (newValue: string) => {
      if (currentFile.type === 'local') {
        setCurrentFile({ ...currentFile, content: newValue, saved: false })
        debouncedSave(newValue)
      }
    },
    // [props.filePath, currentFile, content],
    [debouncedSave, currentFile],
  )

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
      }}
    >
      {(_viewType == 'editor' || _viewType == 'split') && (
        <AceEditor
          keyboardHandler='vim'
          value={content}
          className={'bg-neutral-800 border-none'}
          fileName={currentFile.path}
          outerClassName='flex-1'
          height='100%'
          width='100%'
          wrapEnabled={true}
          theme='nord_dark'
          setOptions={{
            enableBasicAutocompletion: false,
          }}
          enableLiveAutocompletion={true}
          onChange={onUpdate}
          name='UNIQUE_ID_OF_DIV'
          editorProps={{
            $blockScrolling: true,
          }}
        />
      )}
      {(_viewType == 'preview' || _viewType == 'split') && <StakzView />}
    </div>
  )
}
